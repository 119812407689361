import { useState, useEffect } from "react";

import React from 'react';
function Demo() {
  

  const [posts, setPosts] = useState([]);
   useEffect(() => {
    // fetch('http://smartcoach.co.in/ci3/index.php/api/getlectures')

      fetch('ci3/index.php/api/getlectures')
         .then((response) => response.json())
         .then((data) => {
            console.log(data);
            setPosts(data);
         })
         .catch((err) => {
            console.log(err.message);
         });
   }, []);
    return (
        <div>
            <ul>
            {posts && posts.map((item, idx) => (
                // {records.map((list, index)=> (

                <li key={idx}>{item.id} {item.name} <br/> {item.description}</li>


               
                )) }
               
            </ul>

        </div>
    );
    
    }
    
    export default Demo;