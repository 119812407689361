
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
function AccountDeleteProcess() {
    return(
<>
<Container fluid >
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Account Delete Process</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Account Delete Process</a></div>
                    </Col>

                </Row>
                <Row >

                <Col className='m-4'>
                        <h2 className=' m-3 px-2 fw-bold '>Process to delete the account:
                        </h2>
                       <p> To delete the account you have to send a request mail to franctel.network1@gmail.com</p>
                       <p>It should include your account’s username,  registered mobile number along with your full name </p>
                       <p> Mention the reason for deleting the account</p>
                       <p> After confirming your mail details we will verify and proceed for account delete. -You will get an feedback mail once your account is deleted</p>

                    </Col>
                    </Row>

                </ Container >



</>    
    )
    
    }
    
    export default AccountDeleteProcess;