import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import stanlysir from '../../Assets/photo/stanleysir.jpg';
import amolsir from '../../Assets/photo/amolsir.jpg';
// import course from '../../Assets/slide3.jpeg'
import Card from 'react-bootstrap/Card';
import benefitimg from '../../Assets/photo/coursereviewimg.jpg';
import img3 from '../../Assets/vidioanalysis.png';
import img2 from '../../Assets/other/analyzer1.png';
import img1 from '../../Assets/shoot.jpg';
import { Link } from "react-router-dom";
import Marquee from '../../Components/Marquee';
import Testimonials from '../../Components/Testimonials';
import VideoNew from "../../Assets/vidnew.mp4";
function VideoAnalysisCourse() {

    return (

        <>

            <Marquee />

            <Container fluid >
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-center text-white'>CRICKETERS AND COACHES</h1>
                        <h2 className='text-white m-5 px-2 fw-bold text-center'>Video Analysis & Performance Analysis Course</h2>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Online Course</a></div>
                    </Col>

                </Row>

                <Row className='bgBlack '>
                    <h3 className='text-center  text-theme pt-3'>STANLEY SALDANHA – Course Author & Presenter</h3>
                    <Col lg={6}>
                        <Row className="bg-dark text-white m-lg-5 rounded">
                            <Col >
                                <img src={stanlysir} alt='img1' className='img-fluid my-3 rounded' />
                            </Col>
                            <Col>
                                <div className='p-2'>
                                    <h5 className='p-2 text-theme'>Stanley Saldanha:- </h5>

                                    <p className=' p-1'> CEO-Franctel Network Pvt Ltd, Ex-Maharashtra Ranji Trophy Player,
                                        Ex-Manager Game Development ( BCCI), Ex-Tata Co. Sr. Executive</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6}>
                        <Row className="bg-dark text-white m-lg-5 rounded"  >
                            <Col >
                                <img src={amolsir} alt='img1' className='img-fluid my-3 rounded' />
                            </Col>
                            <Col>
                                <div className='p-2'>
                                    <h5 className='p-2 text-theme'>Amol Undale:-</h5>
                                    <p className=' p-1'> Ex-Performance Analyst of Maharashtra Ranji Team,
                                        <br /> Software Engineer - Franctel Network Pvt Ltd</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>

                <Row className='bgBlack '>

                    <Col lg={6} className=' px-3 text-grey'>
                        <h4 className='text-theme text-center'>COURSE REGISTRATION</h4>

                        <div className=' d-flex align-items-center justify-content-center'>

                            <ul >
                                <li className='p-2'>2 Days – 3 hrs Each Day for match analysis software "Analyzer".</li>
                                <li className='p-2'>3 Days with - Technique analysis software "SMART COACH"</li>
                                <li className='p-2'>Course Fees Rs 10,000  for Analyzer and with SMART COACH Rs 15,000</li>
                                <li className='p-2'>Payment in Advance with Registration link</li>
                            </ul>  
                         </div>
                        <div className=' d-flex align-items-center justify-content-center'>
                        <Link to='https://docs.google.com/forms/d/e/1FAIpQLSeXAzij9-DIjotmU73skBK26yPyYcRoq4L2Ui_AFgH0N5RNOQ/viewform?usp=sf_link' className='btn btn-warning mx-lg-2' target="_blank" id='formob'>Register</Link>
                        <Link to='https://razorpay.com/payment-button/pl_MqmfV6xKMUSZLt/view/?utm_source=payment_button&amp;utm_medium=button&amp;utm_campaign=payment_button' className='btn btn-warning mx-lg-2' target="_blank" id='formob'>Pay Here &#8377; 10,000</Link>
                        <Link to='https://razorpay.com/payment-button/pl_MqmiEmuMDbgA8U/view/?utm_source=payment_button&amp;utm_medium=button&amp;utm_campaign=payment_button' className='btn btn-warning mx-lg-2' target="_blank" id='formob'>Pay Here &#8377; 15,000</Link></div>
                    </Col>
                    <Col lg={6} className='px-3 text-grey'>
                        <h4 className='text-theme text-center'> COURSE OVERVIEW</h4>
                        <div className=' d-flex align-items-center justify-content-center'>

                        <ul className='p-3'>
                            <li className='px-2 '> Introduction to Video, Match and Performance analysis</li>
                            <li className='px-2'> The difference between Video Analyst and Performance Analyst</li>
                            <li className='px-2'> Benefits and opportunities for players, coaches and others</li>
                            <li className='px-2'>Roles and responsibilities of video analyst and performance analyst</li>
                            <li className='px-2'>Cameras & video capturing equipment details</li>
                            <li className='px-2'>Video capturing set up for Net Session and Match Analysis</li>
                            <li className='px-2'>Analysis with Match analysis & Technique analysis software</li>
                            <li className='px-2'>Analysis with Match analysis & Technique analysis software</li>
                        </ul>
                        </div>
                    </Col>

                </Row>




                <Row className='bgBlack '>
                    <h3 className='text-center text-theme p-3'>BENEFITS</h3>
                    <Col lg={4} className=' text-center '>
                        <img src={benefitimg} alt='img3' className='img-fluid' />
                    </Col>
                    <Col lg={8} className='px-3 text-grey'>
                        <p>The coaches, players and cricket enthusiasts will be immensely benefited from my experience as a 1st class cricketer, ex- BCCI Manger Game Development ,who created  video analyst set-up at BCCI and as an ex-Corporate Sr. Executive. </p>
                        <p><span className='fw-bold'>Vision :- </span>To create a network of trained performance analysts & opportunities for them to be with state teams, India teams, IPL franchises and also franchises across the globe. During my tenure at BCCI, we could create more than 100 jobs for video analysts at the BCCI . </p>
                        <p><span className='fw-bold'>Cost-effective & Comprehensive course:- :- </span></p>
                        <p><span className='fw-bold'>The course costing is directly related to ooportinities hence made affordable.</span></p>
                        <p>  <span className='fw-bold'> Access to Analyzer and Smart Coach:- </span> For hands-on training trial versions of match and technique analysis software Analyzer and Smart Coach will be provided as a  special offer. You can be a freelance video & performance analyst and work for private academies, cover private tournaments to generate revenue for you with our team support and also software support. If you wish to purchase these software you will be considerer for special discounts </p>

                        <p>ANALYZER and SMART COACH are the products are used by BCCI and most of the associations. Training on these two software will give you better chances to go into the state association or BCCI panel of video analysts</p>

                    </Col>
                </Row>
                <Row className=' bgBlack p-3'>
                    <Col lg={4} className='p-3'>
                        <Card >
                            <Card.Img variant="top" src={img2} />
                            <Card.Body>
                                <Card.Title className='text-center text-theme'>MATCH ANALYSIS</Card.Title>
                                <Card.Text className='text-dark'>
                                    <ul>
                                        <li>ANALYZER is a state-of-the-art match analysis software</li>
                                        <li>Ball-by-ball tagging of match videos  with data</li>
                                        <li>Analyzer has  state-of-the-art reports for performance  analysis</li>
                                        <li>A analyzer is the player's and coaches' delight</li>
                                        <li>Analyzer can stream live videos</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} className='p-3'>
                        <Card className='shadow'>
                            <Card.Img variant="top" src={img1} />
                            <Card.Body>
                                <Card.Title className=' text-center text-theme'>VIDEO ANALYSIS <br /> SHOOT - ANALYSE - IMPROVE</Card.Title>
                                <Card.Text className='text-dark '>
                                    <ul>
                                        <li>Camera  equipment & setup   for analysis</li>
                                        <li>Live video capturing & instant analysis</li>
                                        <li>Identifying players' areas of Improvement</li>
                                        <li>Drawing tools for highlighting areas of improvements</li>
                                        <li>Pre & Post corrections analysis</li>
                                        <li>Generate analysis reports and share them with players </li>
                                       
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} className='p-3'>
                        <Card >
                            <Card.Img variant="top" src={img3} />
                            <Card.Body>
                                <Card.Title className='text-center text-theme'>PERFORMANCE ANALYSIS</Card.Title>
                                <Card.Text className='text-dark '>
                                    <ul>
                                        <li>Key Performing indicators  (KPIs) for players and teams</li>
                                        <li>It has a major role in creating a successful path for players and teams</li>
                                        <li>Strategy planning for opposition and own team</li>
                                        <li>Backend support is necessary to optimise the performance analysis process</li>
                                        <li>Specific performance indicators for players, coaches and selector</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={12} className='p-3'>
                    <h3 className='text-center text-theme p-3'> VIDEO ANALYSIS</h3>

                    <div className='p-3 text-center mt-3'>
                            <video controls className='videoSlide rounded'>
                                <source src={VideoNew} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                    </div>
                    </Col>
                    <Testimonials />

                </Row>



                


            </ Container >


        </>
    )

}

export default VideoAnalysisCourse;