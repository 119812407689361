import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CardImg1 from '../../Assets/carddual.png';
import CardImg3 from '../../Assets/1.jpg';
import CardImg4 from '../../Assets/other/analyzer1.png';

import { Link } from "react-router-dom";

function QuoteCards() {
  return (
    <>

      <Container fluid className='bgBlack text-dark'>


        <Row className='p-3'>

          <h2 className='text-center text-white'>Our Products</h2>

          {/* <Col lg={3} className='p-3'>
            <Card >
              <Card.Img variant="top" src={CardImg2} className='curveImg' alt='img' />
              <Card.Body>
                <Card.Title className='text-center'>Sports Management</Card.Title>
                <Card.Text>
                  Training courses providers for following
                  Cricket – Coaching, Video analysis, Scorer, Umpires

                </Card.Text>
                <div className='text-center'>
                  <Link to='/SportsManagement' className='px-4 btn btn-warning'>Learn More</Link>
                </div>
              </Card.Body>
            </Card>
          </Col> */}

          <Col lg={4} className='p-4'>
            <Card className='bg-black text-white  cardWrapper1 '>
              <Card.Img variant="top" src={CardImg1} className='cardImg1' alt='img' />
              <Card.Body>
                <Card.Title className='text-center text-theme'>Smart Coach Desktop</Card.Title>
                <Card.Text className='text-grey'>
                  Video analysis software for Technique and Skills Improvements. In the desktop version,
                  you can simultaneously capture four videos from different angles for better analysis. Specific
                  analytical tools are available for highlighting areas of improvement. Players will get free
                  Smart Coach player to check their video analysis.
                </Card.Text>
                <div className='text-center'>
                  <Link to='/SmartcoachDesktop' className='px-4 btn btn-warning'>Learn More</Link>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} className='p-4'>
            <Card className='bg-black cardWrapper1 text-white'>
              <Card.Img variant="top" src={CardImg3} className='cardImg1' alt='img' />
              <Card.Body>
                <Card.Title className='text-center text-theme'>Smart Coach Mobile App</Card.Title>
                <Card.Text className='text-grey'>
                  Video analysis software created on a mobile App to reach the maximum number of
                  sportspersons. The App has tremendous potential for sharing analysed videos and
                  improving. The coach&#39;s feedback is quick and can be done online. The culture of
                  self-analysis will get a great boost. You can be a SMART COCH
                </Card.Text>
                <div className='text-center'>
                  <Link to='/SmartCoachApp' className='px-4 btn btn-warning'>Learn More</Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} className='p-4'>
            <Card className='bg-black text-white cardWrapper1'>
              <Card.Img variant="top" src={CardImg4} className='cardImg1' alt='img' />
              <Card.Body>
                <Card.Title className='text-center text-theme'>Video Analysis Course</Card.Title>
                <Card.Text className='text-grey'>
                  Video analysis is purely related to technique and skills analysis. Whereas
                  performance analysis is the combination of analytical processes depending upon the
                  match data and match videos. The course will take you through both the processes
                  with our match analysis software Analyzer and the technical analysis software Smart
                  Coach.
                  {/* As a performance analyst you can contribute a great deal in supporting the
team coach in defining team strategies and also players&#39; strengths and weaknesses
from their own side as well as opponents     */}
                </Card.Text>
                <div className='text-center'>
                  <Link to='/VideoAnalysisCourse' className='px-4 btn btn-warning'>Learn More</Link>
                </div>
              </Card.Body>
            </Card>
          </Col>

        </Row>




      </Container>
    </>)

}

export default QuoteCards;