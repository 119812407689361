import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import VideoC from '../../Assets/video/c.mp4';
function SmartcoachDesktop() {

    return (

        <>


            <Container fluid className=''>
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Smart Coach Desktop</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Smart Coach Desktop</a></div>
                    </Col>

                </Row>
                <Row className='bgBlack p-3'>
                <Col className='  text-center ' lg={7}>
                <div className='p-3 text-center'>
                            <video controls className='videoSlide1 rounded'>
                                <source src={VideoC} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                    </div>
                    </Col>

                    <Col className=' p-5' lg={5}>
                        <h3 className='p-3  text-theme '>Performance enhancement</h3>
                        <div>
                            <ul className=' text-grey'>
                                <li>Players’ flaws in the techniques are often missed with naked eyes.</li>
                                <li>Comparison of match and practice session videos allows
                                    <br/> the player and the coach
                                    to rectify the areas of improvements</li>
                                <li>Comparative analysis with the best athletes/Players  <br/>
                                can help in adopting the best
                                    techniques</li>
                                <li>Self-analysis or support from coaches enhances  <br/> athletes’ / Players&#39; match
                                    performance.</li>
                                <li> Players can learn quickly in their language with recorded audio   <br/>
                                    comments from a coach on their video analysis session</li>
                                <li>Coaches can create a repository of case studies for their academies</li>

                            </ul>
                        </div>

                    </Col>

                    
                    <Col className='' lg={6}>
                        <h3 className='px-4 text-theme '>Injury Prevention</h3>
                        <p className='mx-4 text-grey'>Poor technique and body positioning often contribute   <br/> to sports injuries. Analyzing
                            movement through videos.</p>  <p className='mx-4 text-grey'>players can learn the correct technique to prevent injury.  <br/>
                                Post  injury treatment, video analysis can be used to track progress and recovery</p>
                                <h3 className='px-4  text-theme '>Team Development</h3>
                        <p className='mx-4 text-grey'>Once a players’ video analysis is done, the players &amp; coach can develop a strategy  <br/> 
by addressing strengths & weaknesses  to help with overall team development.</p>
                       
                                </Col>
                                <Col lg={6}>

                                <h3 className='px-4  text-theme '>Individual – Review &amp; Comparison</h3>
                        <p className='mx-4 text-grey'>A player should review the analyzed videos often to prevent past negative habits 
                        <br/> and
                            to maintain consistency of any actions corrected.</p>  <p className='mx-4 text-grey'> Comparative analysis with
                                professional players doing the same skills  for faster improvement</p>
                    </Col>

                </Row>
            </ Container >


        </>
    )

}

export default SmartcoachDesktop;