import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import videoCap from '../../Assets/videocap.jpg';
import singleCam from '../../Assets/singlecam.jpg';
import importcam from '../../Assets/importcam.jpg';
import Video1 from '../../Assets/video2.mp4';
import appdownload from '../../Assets/app1.png';
import VideoApp from '../../Assets/vidapp.mp4';
function SmartCoachApp() {

    return (

        <>
            <Container fluid className=''>
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Smart Coach App</h1>
                        <div className='text-white m-5 fw-bold text-center'>
                            <a href='#' className=' text-white m-3 text-decoration-none '>Home</a>
                            <i className="fa-solid fa-circle-arrow-right "></i>
                            <a className='text-white text-decoration-none m-3'>Smart Coach App</a>
                            {/* <a href='https://play.google.com/store/apps/details?id=com.smart_coach' target="_blank" className='text-white text-decoration-none m-3 btn btn-warning'>Download App</a> */}
                            </div>
                    </Col>
                </Row>
                <Row className='bgBlack '>
                    {/* <Col className='pt-3' sm={12}>
                        <h3 className=' text-center text-theme '>Mobile App</h3>
                        <div className='p-3 text-center'>
                            <video controls className='videoSlide rounded'>
                                <source src={Video1} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>

                    </Col> */}
                    <Col className='pt-3'>
                    <div className='p-3 text-center'>
                            <video controls className='videoSlide1 rounded'>
                                <source src={Video1} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                    </div>
                    </Col>
                    <Col className='pt-3 text-center'>
                    <h6 className='py-3 px-3 '>Scan & Download <i className="fab fa-google-play"></i></h6>

                    <div className='py-2 px-3'><img src={appdownload} alt='app' className='img-fluid '/></div>

                    </Col>
                </Row>
                <Row className='bgBlack '>
                    <Col lg={6}>
                    {/* <h3 className='text-center text-theme p-3'>ANALYSIS VIDEO </h3> */}

                    <div className='p-3 text-center mt-3'>
                            <video controls className='videoSlide1 rounded'>
                                <source src={VideoApp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                    </div>
                    
                    </Col>
                </Row>

                <Row className='bgBlack '>
                    <Col lg={6} className='p-3'>
                        <h3 className='  text-theme text-center '>Key Features</h3>
                        <ul className=' text-grey'>
                            <li>Video capturing on app camera .No dependency on external cameras</li>
                            <li>Import videos captured from any source and uploaded on the mobile </li>
                            <li>Play videos with slow motion and frame by frame </li>
                            <li>Range of drawing &amp; measurement tools
                                <br /> with colour pallets for on-screen Technical Analysis</li>
                            <li>Capture an image with analytical observations</li>
                            <li>To write observations on the video </li>
                            <li>Comparative analysis of players own two videos <br />  based on coach ‘s inputs,
                                before and after corrective measures.</li>
                            <li>Coaches can share demo videos with players for quick learning</li>
                            <li>Comparative analysis of syncronised videos with the best </li>
                            <li>Record audio comments in any language from the coach or mentor</li>
                            <li>Provision to write a report on players&#39; technique analysis </li>
                        </ul>
                    </Col>

                    <Col lg={6} className='p-3'>
                        <h3 className=' text-theme text-center '>Benefits</h3>
                        <ul className=' text-grey'>
                            <li >Every player can utilise the Smart Coach app for improving their game
                                <br />  with
                                the support of their coaches for technique and skills analysis</li>
                            <li >Players can share their videos for analysis with any coach of their choice</li>
                            <li >Instant feed back from coaches can accelerate players game improvement</li>
                            <li >key features can be utilised by players and also coaches</li>
                            <li >It can be of great help to create talent resource</li>
                            <li>Video analysis gives an accelerated improvement to players&#39; performance</li>
                        </ul>
                    </Col>
                </Row>
            </ Container >
        </>
    )

}

export default SmartCoachApp;