import React from 'react';

// import Banner from '../../Components/Banner';
// import Slider from '../../Components/Slider';
// import one from '../../Assets/slide1.jpg';
// import two from '../../Assets/slide2.jpg';
// import three from '../../Assets/slide3.jpeg';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Client1 from '../../Assets/clients/c1.png';
import Client2 from '../../Assets/clients/c2.png'
import Client3 from '../../Assets/clients/c3.png'
import Client4 from '../../Assets/clients/c4.png'
import Client5 from '../../Assets/clients/c5.png'
import Client6 from '../../Assets/clients/c6.png'
import Client7 from '../../Assets/clients/gujrat.png'
import Client8 from '../../Assets/clients/c8.png'
import Client9 from '../../Assets/clients/c9.png'
import Client10 from '../../Assets/clients/c10.png'
import Client11 from '../../Assets/clients/c11.png'
import Client12 from '../../Assets/clients/c12.png'
import Client13 from '../../Assets/clients/c13.png'
import Client14 from '../../Assets/clients/c14.png'
import Client15 from '../../Assets/clients/c15.png'
import Client16 from '../../Assets/clients/c16.png';
// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import feature from '../../Assets/cricketer.png'
import benefits from '../../Assets/runner.png'
import coach from '../../Assets/coach4.png'
import { Link } from "react-router-dom";
import $ from 'jquery';
import QuoteCards from '../../Components/QuoteCards';
import Marquee from '../../Components/Marquee';
import BgVid from '../../Assets/mix1.mp4';
function Home() {

    $('.counting').each(function () {
        var $this = $(this),
            countTo = $this.attr('data-count');

        $({ countNum: $this.text() }).animate({
            countNum: countTo
        },
            {

                duration: 3000,
                easing: 'linear',
                step: function () {
                    $this.text(Math.floor(this.countNum));
                },
                complete: function () {
                    $this.text(this.countNum);
                    //alert('finished');
                }
            });


    });
    // var img = [
    //     { img: two, caption: "IMPROVE TEAMS' AND ATHLETES' PERFORMANCE", subcaption: "Video Analysis Solutions for Sports Performance", moto: "Use video and data analysis to detect vulnerabilities. Decide what to focus on and develop technical and tactical strategies to improve performance and reduce injuries."  },
    //     { img: one, caption: "IMPROVE TEAMS' AND ATHLETES' PERFORMANCE", subcaption: "Video Analysis Solutions for Sports Performance", moto: "Use video and data analysis to detect vulnerabilities. Decide what to focus on and develop technical and tactical strategies to improve performance and reduce injuries."  },
    //     { img: three, caption: "VIDEO ANALYSIS AND PERFORMANCE ANALYSIS COURSE", subcaption: "Cricketers and Coaches across the globe convert your passion in to new career as a performance analysis", moto: "Conducted by Stanley Saldanha, Ex-Ranji trophy cricketer and Ex-BCCI Manager Game Development",button:'Enroll Now' }
    // ];

    // <Slider images={img} />

    return (
        <>
                    <Marquee />

            <div className='bgBlack'>

                <video autoPlay muted loop id="background-video" >
                    <source src={BgVid} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

             <div id="contentVid">
            

             <div className='text-start py-5 '>
                <div className='my-lg-5 mx-lg-1 py-lg-5 '>
                          
                    <span className='heading1'>
                    SMART COACH
                    </span><br />
                    <span className='heading3 text-theme'>Video Analysis Desktop Software
                    <br /></span>
                    <span className='heading6 '> Technique and Skills Development
                    </span><br />

                    <span className='heading3 text-theme'>Smart Coach Mobile app
                    <br /></span>
                    <span className='heading6 '>Videos Sharing for Analysis with Coaches
                    </span><br />

                    <span className='heading3 text-theme'>Video Analysis course
                    <br /></span>
                    <span className='heading6 '>For coaches, Players and Cricket enthusiasts<br />

New opportunities
                    </span><br />


                </div>
             </div>
            </div>

            </div>

            {/* <Slider /> */}
            {/* <Banner /> */}
            <QuoteCards />

            <Container fluid className='bgBlack'>

                <Row>

                    <Col className='p-3 m-3'>
                        {/* <hr className='p-5' /> */}

                        <h2 className=' px-4 text-theme text-center'>WHY USE SMARTCOACH?</h2>
                        {/* <h1 className='px-4 py-1 fw-bold'>We Are the Leading Experts in <br /> Sports Performance Analysis</h1>
                        <h3 className='text-grey px-4'>Smartcoach has designed products to fit any workflow. <br /> Whatever your goals are</h3> */}
                    </Col>
                </Row>
                <Row className='p-3 m-4'>

                    <Col lg={4} className='p-3'>
                        <Link to="/KeyFeatures" className='text-decoration-none'>
                            <Card className='shadow '>
                                <Card.Img variant="top" src={feature} />
                                <Card.Body className='bgBlack'>
                                    <Card.Title className=' text-center text-theme'>KEY FEATURES</Card.Title>
                                    <Card.Text className='text-dark p-3'>
                                        <ul className='text-white'>
                                            <li> Video analysis for all sports</li>
                                            <li> Syncronised Dual, quad videos</li>
                                            <li>Four views for single event</li>
                                            <li>Technique and Skills analysis</li>
                                            <li> Live videos streaming facility</li>
                                            <li>Measurement and drawing tools</li>
                                            <li> Ball tracking a unique feature</li>

                                        </ul>
                                    </Card.Text>
                                    <div className='arrowBox'> <i className="fa-solid fa-arrow-right text-dark arrow"></i></div>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                    <Col lg={4} className='p-3'>
                        <Link to="/Benefits" className='text-decoration-none'>

                            <Card >
                                <Card.Img variant="top" src={benefits} />
                                <Card.Body className='bgBlack'>
                                    <Card.Title className='text-center text-theme'>BENEFITS</Card.Title>
                                    <Card.Text className='text-dark p-3'>
                                        <ul className='text-white'>
                                            <li> Identification of technical flaws</li>
                                            <li> Self-analysis by athletes</li>
                                            <li> On-screen graphic analysis with measurement and drawing tools</li>
                                            <li> Voice over feedback by coaches for the areas of improvements</li>
                                            <li> "Biomechanical analysis" for Injury</li>
                                        </ul>
                                    </Card.Text>
                                    <div className='arrowBox'> <i className="fa-solid fa-arrow-right text-dark arrow"></i></div>                            </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                    <Col lg={4} className='p-3'>
                        <Link to="/DemoVideo" className='text-decoration-none'>

                            <Card >
                                <Card.Img variant="top" src={coach} />
                                <Card.Body className='bgBlack'>
                                    <Card.Title className='text-center text-theme'>COACHE'S CORNER</Card.Title>
                                    <Card.Text className='text-dark p-3'>
                                        <ul className='text-white'>
                                            <li> Advantage to coaches for video based analytical assessment</li>
                                            <li> Targeted areas of improvements</li>
                                            <li> Analyse before & after corrections</li>
                                            <li>Explore Smart Coach tools</li>
                                            <li>Involve players in analysis sessions</li>
                                            <li>Videos repository for follow up</li>

                                        </ul>
                                    </Card.Text>
                                    <div className='arrowBox'> <i className="fa-solid fa-arrow-right text-dark arrow"></i></div>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                </Row>


                <Row>
                    <h3 className=' px-4 text-theme text-center'>SMART COACH</h3>

                    <section id="counter-stats" className="wow fadeInRight pb-5" data-wow-duration="1.4s">
                        <div className="container">
                            <div className="row">



                                <div className="col-lg-3 stats">
                                    <i className="fa-solid fa-download"></i>
                                    <div className="counting" data-count="280">100</div>
                                    <h5>App Downloads</h5>
                                </div>

                                <div className="col-lg-3 stats">
                                    <i className="fa-regular fa-face-smile"></i>                                               <div className="counting" data-count="750">80</div>
                                    <h5>Happy clients</h5>
                                </div>
                                <div className="col-lg-3 stats">
                                    <i className="fa-regular fa-eye"></i>
                                    <div className="counting" data-count="5000">4000</div>
                                    <h5>Website Visitors</h5>
                                </div>
                                <div className="col-lg-3 stats">
                                    <i className="fa-solid fa-calendar-days"></i>                                             <div className="counting" data-count="6">4</div>
                                    <h5>Years of Service</h5>
                                </div>



                            </div>
                        </div>

                    </section>

                </Row>

                <Row className='p-1'>
                    <Col className='p-2 text-center' >
                        <img src={Client1} alt='client1' className='img-fluid p-3' />
                        <img src={Client2} alt='client1' className='img-fluid p-3' />
                        <img src={Client3} alt='client1' className='img-fluid p-3' />
                        <img src={Client4} alt='client1' className='img-fluid p-3' />
                        <img src={Client5} alt='client1' className='img-fluid p-3' />
                        <img src={Client6} alt='client1' className='img-fluid p-3' />
                        <img src={Client7} alt='client1' className='img-fluid p-3' />
                        <img src={Client8} alt='client1' className='img-fluid p-3' />

                    </Col>
                </Row>

                <Row className='p-1'>
                    <Col className='p-2 text-center' >
                        <img src={Client9} alt='client1' className='img-fluid p-3' />
                        <img src={Client10} alt='client1' className='img-fluid p-3' />
                        <img src={Client11} alt='client1' className='img-fluid p-3' />
                        <img src={Client12} alt='client1' className='img-fluid p-3' />
                        <img src={Client13} alt='client1' className='img-fluid p-3' />
                        <img src={Client14} alt='client1' className='img-fluid p-3' />
                        <img src={Client15} alt='client1' className='img-fluid p-3' />
                        <img src={Client16} alt='client1' className='img-fluid p-3' />
                    </Col>
                </Row>



            </Container>



        </>
    )

}

export default Home;