import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';

function Upload() {


    return(
        <>
        
        
   

<Container fluid className=' p-3'>
                <Row>
                    <Col className='text-center '>
                        <p className='text-center'>Click "Choose File" button to upload a file:</p>
                        <div className='text-center'>
                    <input type="file" id="myfile" name="myfile" />
                        </div>
                        {/* <input type="submit" /> */}


                    </Col>
                </Row>


            </Container>





        </>
    
    )
    
    }
    
    export default Upload;