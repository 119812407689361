import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import gazet from '../../Assets/gazet1.png';
function About() {

    return (

        <>


            <Container fluid className=''>
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Learn more about Smart Coach</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>About Us</a></div>
                    </Col>

                </Row>
                <Row className='bgBlack'>
                <h3 className='text-center p-4 text-theme'>OUR STORY</h3>


                    <Col className='textJustify' lg={6}>

                        <h1 className='text-center p-2'>The making of Smart Coach</h1>
                        <p className='text-grey mx-3'>We at Franctel Network Pvt Ltd under the leadership of Stanley Saldanha have created sports analysis software to support athletes to enhance their performance during matches and events. Stanley is a former 1st class cricketer from India and also an EX- Manager of Game Development for the Board of Control For Cricket In India(BCCI). With his cricket playing and administration experience combined with 25 years of corporate experience with a renowned Tata Co., he has created a cricket match analysis software Analyzer in 3 different versions and also desktop based Technique analysis software SMART COACH useful for all sports. The Analyzer is used by BCCI for umpires' performance analysis covering 1800 domestic matches & the higher version is used by state associations for teams & players' performance analysis along with “ Smart Coach”. The Smart Coach is also used by many sports academies. It plays a major role in improving players' skills & technique,  coaches can use it to the best of their ability, using a  lot of analytical tools and report formats.</p>
                        <p className='text-grey mx-3'>There are two versions of Smart Coach,  desktop application and the other on a mobile application. The mobile application android version is already available on the play store. Very soon ios version for I-phone and I-pad will be available. </p>

                    </Col>
                    <Col className='' lg={6}>
                        <div className='p-3 text-center'>
                        <img src={gazet} alt='img' className='img-fluid rounded'/>

                        </div>
                    </Col>

                </Row>
                
            </ Container >



        </>
    )

}

export default About;