import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
// import benefitvid from '../../Assets/video/benefitvid.mp4';
function Benefits() {

    return (

        <>


            <Container fluid >
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Benefits</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Benefits</a></div>
                    </Col>

                </Row>

                <Row className='bgBlack '>


                    <Col className='p-3 m-3'>
                        <h3 className='text-center p-4 text-theme '>Benefits</h3>
                        <Row>
                            {/* <Col lg={3}>
                                <div className=' px-3' >

                                    <iframe
                                        src={benefitvid}
                                        title="Vimeo video"
                                        allowFullScreen
                                        className='rounded-4 p-1'
                                    ></iframe></div>
                            </Col> */}

                            <Col >
                                <p className='text-grey py-2'>The comparison of match & practice sessions videos help the coaches to identify areas of improvement and correct technical flaws. The Video analysis is done before corrections and after corrections can be shared with different coaches and players as case studies. The software has wonderful report formats where coaches can create their templates for writing analytical reports and export the same. The exported video analysis report of any player or an athlete can be viewed on the SMART COACH player which will be shared free of cost with an exported report. The biggest benefit is result-oriented match performance post video analysis.</p>
                            </Col>
                        </Row>



                        <h6 className=' px-4'>Injury Prevention</h6>
                        <p className='text-grey px-4'>Identification of poor technique and body positions often contribute to sports injuries. Smart coach with the support of a coach and physio can analyse these areas to prevent injuries. The player then can learn the correct technique to prevent additional pain or injury. After an injury has occurred, video analysis can also be used to track progress and recovery.</p>
                        <h6 className=' px-4'>Team Development</h6>
                        <p className='text-grey px-4'>Once a players’ video analysis is done, the players & coach can develop a strategy by addressing any weaknesses and taking advantage of any strengths that are observed to help with overall team development.</p>
                    </Col>




                </Row>
            </ Container >


        </>
    )

}

export default Benefits;