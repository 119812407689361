import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Otp() {


    return(
        <>
        
        
   

<Container fluid className=' '>
                <Row>
                    <Col className='m-4 d-flex justify-content-center align-items-center '>
                        
                        <Form action='#'  className='border m-3 p-3 bg-info rounded'>
                           

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>OTP VERIFICATION</Form.Label><br/>
                                <Form.Control type="tel"  placeholder="Enter OTP" />
                                <Form.Label className='text-muted pt-2'>Enter 6 digit code  sent on <br/> your registered mobile</Form.Label><br/>

                            </Form.Group>
                           <Button variant="success" type="submit" >
                                Submit
                            </Button>
                        </Form>
   

                    </Col>
                </Row>


            </Container>





        </>
    
    )
    
    }
    
    export default Otp;