import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Tech from '../../Assets/tech.jpg';
function TechnicalSupport() {

    return (

        <>


            <Container fluid className=''>
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Technical Support</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Technical Support</a></div>
                    </Col>

                </Row>
                <Row className='bgBlack'>

                    <Col className='p-3'>
                        <h3 className='text-center p-4 text-theme '>24/7 Support</h3>
                        <div className='text-center'>
                            <img src={Tech} alt='img' className='img-fluid' />
                        </div>
                    </Col>

                </Row>
            </ Container >


        </>
    )

}

export default TechnicalSupport;