import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';

function Pricing() {

    return (

        <>


            <Container fluid >
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Subscription</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Subscription</a></div>
                    </Col>

                </Row>
                <Row className='bgBlack'>


                    <Col className='m-3 '>

                        <h1 className='text-yellow p-2 text-center'>SMART COACH</h1>

                        <p className='px-3'>Contact : +919820518487 / +91-9850900325  </p>
                        <p className='px-3'>franctel.network1@gmail.com</p>

                        <Row className='d-flex align-items-center justify-content-center p-4'>
                            <Col lg={6}>
                                <Card varient='dark' className='bg-dark'>
                                    <Card.Body className='text-white'>
                                        <Card.Title className='text-center p-2'>Smart Coach Desktop</Card.Title>


                                        <Card.Subtitle className="mb-2 text-white text-center "><span className='p-1'>CAPTURE</span> <span className='p-1'> ANALYZE</span> <span > IMPROVE</span>         </Card.Subtitle>
                                        <Card.Text className='text-center'>
                                            <Row className='p-3'>
                                                <Col sm={4} className='p-1'>
                                                    <h6 > 3 months  </h6 ><span ><i className="fa-solid fa-indian-rupee-sign"></i>  6500 + <i className="fa-solid fa-indian-rupee-sign"></i> 1170 (GST)<br /> = <i className="fa-solid fa-indian-rupee-sign"></i> 7670</span> <br />(USD $ 78)

                                                    <div className='py-2'> <Link to='https://razorpay.com/payment-button/pl_MDCBc54ZTdSe2T/view/?utm_source=payment_button&amp;utm_medium=button&amp;utm_campaign=payment_button' className='btn btn-warning mx-lg-2 ' id='formob' target="_blank"> Purchase </Link> </div>
                                                </Col>
                                                <Col sm={4} className='p-1'>
                                                    <h6 > 6 months </h6><span > <i className="fa-solid fa-indian-rupee-sign"></i>
                                                    12500 + <i className="fa-solid fa-indian-rupee-sign"></i> 2250 (GST)<br /> = <i className="fa-solid fa-indian-rupee-sign"></i> 14750  </span> <br />(USD $ 156  )
                                                    <div className='py-2'> <Link to='https://razorpay.com/payment-button/pl_MDC3l69WLIASiQ/view/?utm_source=payment_button&amp;utm_medium=button&amp;utm_campaign=payment_button' className='btn btn-warning mx-lg-2 ' id='formob' target="_blank"> Purchase </Link> </div>  </Col>
                                                <Col sm={4} className='p-1'>
                                                    <h6 >  12 months </h6><span >  <i className="fa-solid fa-indian-rupee-sign"></i> 25000 + <i className="fa-solid fa-indian-rupee-sign"></i> 4500 (GST)<br /> = <i className="fa-solid fa-indian-rupee-sign"></i> 29500</span>
                                                    <br />(USD $ 312 )
                                                    <div className='py-2'> <Link to='https://razorpay.com/payment-button/pl_LcSQBvV0nUgpDh/view/?utm_source=payment_button&amp;utm_medium=button&amp;utm_campaign=payment_button' className='btn btn-warning mx-lg-2 ' id='formob' target="_blank"> Purchase </Link> </div>
                                                </Col></Row>
                                        </Card.Text>

                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col lg={6}>
                                <Card varient='dark' className='bg-dark'>
                                    <Card.Body className='text-white'>
                                        <Card.Title className='text-center p-2'>Smart Coach Mobile App</Card.Title>


                                        <Card.Subtitle className="mb-2 text-white text-center"><span className='p-1'>CAPTURE</span> <span className='p-1'> ANALYZE</span> <span > IMPROVE</span>         </Card.Subtitle>
                                        <Card.Text className='text-center'>
                                         
                                            {/* <Row className='p-2'>
                                                <Col className='p-3'>
                                                    <div>
                                                        <h6 > 3 months  </h6 >
                                                        <i className="fa-solid fa-indian-rupee-sign"></i>  200 (USD $ 5)
                                                    </div>   
                                              </Col>

                                                <Col className='p-3'>

                                                    <div>
                                                        <h6 > 6 months </h6>
                                                        <i className="fa-solid fa-indian-rupee-sign"></i>  300  (USD $ 10  )
                                                    </div>
                                                </Col>

                                            </Row> */}
                                            <Row className='p-1'>
                                                <Col className='p-2'>
                                                    <div>
                                                        <h6 >  12 months </h6>
                                                        <i className="fa-solid fa-indian-rupee-sign"></i>  500 (USD $ 15 )
                                                    </div>
                                                    
                                                     </Col>

                                                <Col className='p-2'>
                                                    <div className='py-2  text-grey d-flex align-items-center justify-content-center'>
                                                        <a href='https://play.google.com/store/apps/details?id=com.smart_coach' target="_blank" className='btn btn-info text-center d-flex align-items-center justify-content-center'><i className="fa-brands fa-google-play fs-1"></i> <span className='text-center d-flex align-items-center justify-content-center'> Download the App</span> </a>
                                                    </div>
                                                </Col>

                                            </Row>


                                        </Card.Text>

                                    </Card.Body>

                                </Card>

                            </Col>

                        </Row>
                    </Col>

                </Row>
            </ Container >


        </>
    )
}
export default Pricing;


