
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import $ from 'jquery';
<script
  src="https://code.jquery.com/jquery-3.6.0.min.js"
  integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4="
  crossorigin="anonymous">
  </script>

function NewForm() {
    $('#contactForm').submit(function (e) {
        e.preventDefault(); // avoid to execute the actual submit of the form.

        
        var form = $(this);
        var url = form.attr('action');
        
        $.ajax({
            type: "POST",
            url: url,
            data: form.serialize(), // serializes the form's elements.
            success: function(data)
            {
                alert(data); // show server response 
            }
        });
    });


    return (
        <>

            <Container fluid className=''>
              
                <Row className='d-flex justify-content-center justify-content-center'>
                    <Col className='m-4' lg={6}>
                    <form id="contactForm" className="w-75 mx-auto" action="https://smartcoach.co.in/ci3/index.php/Api/savecontentdata">
    <input type="text" className="form-control bg-transparent" placeholder="Name" name='user_name'/>
    <input type="email" className="form-control bg-transparent my-3" placeholder="E-mail" name='user_contact' />
    <input type="text" className="form-control bg-transparent my-3" placeholder="E-mail" name='user_address' />
    <input type="text" className="form-control bg-transparent my-3" placeholder="E-mail" name='Email'/>
    <input type="text" className="form-control bg-transparent my-3" placeholder="E-mail" name='Gender'/>
    <input type="text" className="form-control bg-transparent my-3" placeholder="E-mail" name='Occupation'/>
    <input type="text" className="form-control bg-transparent my-3" placeholder="E-mail" name='Paymentstatus'/>
    <input type="text" className="form-control bg-transparent my-3" placeholder="E-mail" name='Loginpassword'/>
    <input type="text" className="form-control bg-transparent my-3" placeholder="E-mail" name='CourseStatus'/>
    <input type="text" className="form-control bg-transparent my-3" placeholder="E-mail" name='AdmissionType'/>
    <input type="text" className="form-control bg-transparent my-3" placeholder="E-mail" name='CreatedDate'/>
    <input type="text" className="form-control bg-transparent my-3" placeholder="E-mail" name='ModifyDate'/>

    <button type="submit" className="my-3 px-4 py-2 rounded-3">SEND</button>
</form>
                    </Col>

                </Row>


            </ Container >

        </>
    )

}

export default NewForm;