
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';

function UserForm() {
    //     const thisForm = document.querySelector('.userform');
    //     thisForm && thisForm.addEventListener('submit', e => {
    //     e.preventDefault();
    //     const formData = new FormData(thisForm);
    //     const data = new URLSearchParams(formData)
    //      fetch('https://smartcoach.co.in/ci3/index.php/Api/savecontentdata', {
    //         method: 'POST',
    //         body: data
    //     }).then(res => res.json())
    // .then(data => console.log(data))
    // .catch(error => console.log(error))

    // });


    return (
        <>

            <Container fluid className=''>
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>User Form</h1>

                    </Col>

                </Row>

                <Row className='d-flex justify-content-center justify-content-center'>
                    <Col className='m-4' lg={6}>
                        <h1 className='text-dark  p-2 fw-bold text-center text-shadow'>Registration Form</h1>

                        <form id="UserForm" method='post' className='userform' action='new.php'>
                            <div className="row mb-3">
                                <label htmlFor="inputName" className="col-sm-2 col-form-label">Name</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" placeholder="Name" name="first_name" id="firstName" aria-label="Name" />  </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Email</label>
                                <div className="col-sm-10">
                                    <input type="email" className="form-control" name="email" id="emailAddress" placeholder="Email" />
                                </div>

                            </div>
                            <fieldset className="row mb-3">
                                <legend className="col-form-label col-sm-2 pt-0">Gender</legend>
                                <div className="col-sm-10">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="gender" id="gender1" value="Male" checked />
                                        <label className="form-check-label" htmlFor="gridRadios1">
                                            Male
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="gender" id="gender2" value="Female" />
                                        <label className="form-check-label" htmlFor="gridRadios2">
                                            Female
                                        </label>
                                    </div>

                                </div>
                            </fieldset>
                            <div className="row mb-3">
                                <label htmlFor="inputOccupation" className="col-sm-2 col-form-label">Occupation</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="occupation" id='occupation' placeholder="Occupation" aria-label="Occupation" />  </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="user_contact" className="col-sm-2 col-form-label">Mobile Number</label>
                                <div className="col-sm-10">
                                    <input type="number" className="form-control" name="user_contact" id='user_contact' placeholder="Mobile Number" aria-label="user_contact" />  </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputAddress" className="col-sm-2 form-label">Address</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="user_address" id="user_address" placeholder="Address" />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label htmlFor="inputAddress" className="col-sm-2 form-label">Admission Type</label>
                                <div className="col-sm-10">

                                    <select class="form-select" aria-label="Default select example" name="AdmissionType">
                                        <option selected>select Admission Type</option>
                                        <option value="Regular">Regular</option>
                                    </select> 
                                 </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputAddress" className="col-sm-2 form-label">Admission Date</label>
                                <div className="col-sm-10">

                                <input type="text"  id="datepicker" name="CreatedDate" value={new Date().toISOString().substring(0, 10)} />

                                 </div>
                            </div>

                            <button type="submit" id="postSubmit" value="Submit" className="btn btn-primary">Submit</button>

                        </form>
                    </Col>

                </Row>


            </ Container >

        </>
    )

}

export default UserForm;