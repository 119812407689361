import React from 'react';
import {  Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import videoCap from '../../Assets/videocap.jpg';
import singleCam from '../../Assets/singlecam.jpg';
import importcam from '../../Assets/importcam.jpg';
function KeyFeatures() {

    return (

        <>


            <Container fluid className=''>
            <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Key Features</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Key Features</a></div>
                    </Col>
                    
                </Row>
                <Row className='bgBlack '>


                    <Col className='p-3'>
                    <h3 className='text-center p-4 text-theme '>Key Features</h3>
                    <div>
                   <ul className='m-4'>
                    <li>Video slide with 4 comments to pinpoint areas of technical flaws</li>
                    <li>Synchronised Dual and quad videos for comparative analysis</li>
                    <li>LBW strip for cricket and 3 other Image overlays available</li>
                    <li>Magnifier to focus on a particular area of a video</li>
                    <li>Analysed reports by coaches can be written in any language</li>
                    <li>Coaches can record verbal and visual analysis and then share</li>
                    <li>The exported report comes with a free SMART COACH player</li>
                    <li>Coaches can create their own coaching templates</li>
                    <li>Video Trimming</li>

                   </ul>
                   </div>
                   <h4 className='p-4 text-theme '>VIDEO CAPTURE</h4>
                   <h6 className='px-4'> Live Videos Capture: With IP and non IP Cameras</h6>
                    <p className='px-4'>You can connect upto 4IP cameras connected with LAN Cable for Live Video Capturing of any sporting event and directly analyze in to SMART COACH</p>
                   
                       <img src={videoCap} alt='img2' className='img-fluid px-4'/>
                       <p className='px-4'>Non IP Cameras</p>
                       <h6 className='px-4'>You can connect upto 4IP cameras connected with LAN Cable for Live Video Capturing of any sporting event and directly analyze in to SMART COACH</h6>
                        <img src={singleCam} alt='img2' className='img-fluid px-4'/>
                        <p className='px-4'>Single Camera</p>
                       <h6 className='px-4'>For single camera you can directly connect with Laptop through video capturing card like Aver media etc.</h6>
                       <img src={importcam} alt='img2' className='img-fluid px-4'/>
                       <h5 className='px-4 py-2 text-theme '>IMPORT OF VIDEOS</h5>
                       <div>
                        <ul className='mx-4'>
                            <li>From the Video recording window of Smart coach, browse for videos through files and folders</li>
                            <li>You can load multiple files at one go for analysis and select as per requirement</li>
                            <li>The USER GUIDE will give all the details about the video capturing process</li>

                        </ul>
                        </div>
                        <p className='mx-4'>video</p>
                    </Col>

                </Row>
            </ Container >


        </>
    )

}

export default KeyFeatures;