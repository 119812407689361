import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
// import benefitvid from '../../Assets/video/benefitvid.mp4';
// import { Link } from "react-router-dom";

function VideoAnalysis() {

    return (

        <>


            <Container fluid className=''>
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Video Analysis</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Video Analysis</a></div>
                    </Col>

                </Row>
                <Row className='bgBlack'>


                    <Col className='p-4'>
                        <h3 className='text-center p-4 text-theme '>ONLINE VIDEO ANALYSIS</h3>

                        {/* <h4 className='text-white px-3'>ONLINE VIDEO ANALYSIS & COACHING</h4> */}
                     

                        <h5 className=' px-3 text-white'>Cricket : </h5>

                        <p className='px-3 text-grey'>Video analysis in sports has a vital role to play in the development of any sportsman.
                            It helps in the match performance at an accelerated rate. We at SMART COACH not
                            only sale our product but also use it for providing online professional video analysis
                            service from renowned coaches. Modern day cricket demands lot of innovative ways
                            to perform whether batting, bowling or fielding. Although the basics of cricket remain
                            same the technique and skills need constant changes, variations as per format of
                            match and also the levels to achieve success.</p>

                     

                        {/* <div className=' px-3' >

                            <iframe
                                src={benefitvid}
                                title="Vimeo video"
                                allowFullScreen
                                className='rounded-4 p-1'
                            ></iframe></div> */}
                    </Col>

                </Row>
            </ Container >


        </>
    )

}

export default VideoAnalysis;