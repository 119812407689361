import React from 'react';

import { Container } from 'react-bootstrap';

import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import appdownload from '../../Assets/app.png';
// import playstore from '../../Assets/ppp.png';
function Footer() {

  return (

    <>

      <footer className='bgBlack'>
        <Container fluid >
          <Row className='p-3 text-center'>
            {/* <Col lg={3}>
              <h6 className='py-3 px-3'>SOLUTIONS</h6>
              <div className='lightTxt py-2 px-3'>Sports</div>
              <div className='lightTxt py-2 px-3'>Healthcare</div>
              <div className='lightTxt py-2 px-3'>Education</div>
            </Col> */}


            <Col lg={3}>
              <h6 className='py-3 px-3'>COMPANY</h6>
              {/* <div className='lightTxt py-1 px-3'><Link to="/About" className='lightTxt text-decoration-none text-white'>About Us</Link></div> */}
              <div className='lightTxt py-2 px-3'><Link to="/KeyFeatures" className='lightTxt text-decoration-none text-grey'>Key Features</Link></div>
              <div className='lightTxt py-2 px-3'><Link to="/Benefits" className='lightTxt text-decoration-none text-grey'>Benefits</Link></div>
              <div className='lightTxt py-2 px-3'><Link to="/UserGuide" className='lightTxt text-decoration-none text-grey'>User Guide</Link></div>
              <div className='lightTxt py-1 px-3'><Link to="/TechnicalSupport" className='lightTxt text-decoration-none text-grey'>Technical Support</Link></div>
            </Col>
            <Col lg={3}>
              <h6 className='py-3 px-3'>PRODUCTS</h6>
              <div className=' py-2 px-3'><Link to="/SmartcoachDesktop" className='text-grey text-decoration-none '>Smart Coach Desktop</Link></div>
              <div className=' py-2 px-3'><Link to="/SmartCoachApp" className='text-grey text-decoration-none '>Smart Coach App</Link></div>
              <div className=' py-2 px-3'><Link to="/VideoAnalysisCourse" className='text-grey text-decoration-none '>Video Analysis Course</Link></div>
              <div className=' py-2 px-3'><Link to="/SportsManagement" className='text-grey text-decoration-none '>Sports Management</Link></div>
              <div className=' py-2 px-3'><Link to="/VideoAnalysis" className='text-grey text-decoration-none '>Video Analysis</Link></div>
           
              
            </Col>
            <Col lg={3}>
              <h6 className='py-3 px-3'>QUICK LINKS</h6>
              <div className='py-2 px-3'><Link to="/Privacy" className='lightTxt text-decoration-none text-grey'>Privacy Policy</Link></div>
              <div className='py-2 px-3 text-grey'><Link to="/Disclaimer" className='lightTxt text-decoration-none text-grey'>Disclaimer</Link></div>
              <div className='py-2 px-3 text-grey'><Link to="/Terms" className='lightTxt text-decoration-none text-grey'>Terms of Use</Link></div>
              <div className='py-2 px-3 text-grey'><Link to="/Copyright" className='lightTxt text-decoration-none text-grey'>Copyright Notice</Link></div>
            </Col>
            <Col lg={3}>
            <h6 className='py-3 px-3'>Download the App   <i className="fab fa-google-play"></i></h6>
            {/* <h6 className='py-3 px-3'>DOWNLOAD THE APP <img src={playstore} alt='img' className='img-fluid'/> </h6> */}
          
              <div className='py-2 px-3 text-grey'><img src={appdownload} alt='app' className='img-fluid '/></div>
            </Col>
          </Row>

          <Row>
            <Col className='p-3'>
              <h5 className='text-center p-2'>Follow Us</h5>
              <div className='d-flex justify-content-between justify-content-sm-center my-4'>
                <a href="https://www.youtube.com/channel/UCL4g2dA0Z078TxbpIQIZMmA" target="_blank"><i className="fa-brands fa-youtube fs-1 mx-2 text-white rotate"></i></a>
                <a href="https://www.instagram.com/smartcoach.co.in/" target="_blank"><i className="fa-brands fa-instagram fs-1 mx-2 text-white rotate"></i></a>
                <a href="https://www.facebook.com/profile.php?id=100066258716847" target="_blank"><i className="fa-brands fa-facebook fs-1 mx-2 text-white rotate"></i></a>
                <a href="https://www.linkedin.com/in/smart-coach-b02bbb270/" target="_blank"><i className="fa-brands fa-linkedin fs-1 mx-2 text-white rotate"></i></a>
                <a href="https://twitter.com/SmartcoachCo" target="_blank"><i className="fa-brands fa-twitter fs-1 mx-2 text-white rotate"></i></a>
              </div>
            </Col>
          </Row>


          <Row>
            <Col className='text-center p-2'>
              <span >@{new Date().getFullYear()}  smartcoach.co.in
              </span>
            </Col>
          </Row>
        </Container>
      </footer>

    </>
  )

}

export default Footer;