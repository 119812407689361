import React from 'react';
import {  Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Setup from '../../Assets/setup1.zip';
function Downloads() {

    return (

        <>


            <Container fluid className=''>
            <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Downloads</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Downloads </a></div>
                    </Col>
                    
                </Row>
                <Row className='bgBlack'>


                    <Col className='p-3'>
                    <h3 className=' p-4 text-theme '>Download the Setup File</h3>
                    <p className='px-4 textJustify'>
                        Download the smart coach setup zip file. Extract and install the smartcoach2.0 on your windows PC. On first run you will be asked for license key. To get the license key copy the text in the activation code text box and send it to our email id franctel.network1@gmail.com along with your name and payment details of smart coach. In reply you will receive the activation key which you have to copy in activation code text box and activate the smartcoach2.0 according to your subscription plan. In case of any problem regarding payment or activation please reach us by contacting our email @ franctel.network1@gmail.com
                    </p>
                    <div className='px-4'>
                    <a href={Setup} target='_blank' className='btn btn-info'>Click to Download</a>
                    </div>
                    </Col>
                

                </Row>
            </ Container >


        </>
    )

}

export default Downloads;