import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import SetupM from '../../Assets/Mediasetup.zip';
function MediaSetup() {

    return (

        <>


            <Container fluid className=''>
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Media Setup</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Media Setup </a></div>
                    </Col>

                </Row>
                <Row className='bgBlack'>


                    <Col className='p-3'>
                        <h3 className=' p-3 text-theme '>Download the Setup File</h3>
                    
                      
                        <div className='px-4'>
                            <a href={SetupM} target='_blank' className='btn btn-info'>Click to Download</a>
                        </div>
                    </Col>


                </Row>
            </ Container >


        </>
    )

}

export default MediaSetup;