import React from 'react';

import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../Assets/logo2.png'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
function Header() {
//   window.onscroll = ()=>{
//     if(window.scrollY > 100){
//           document.getElementsByTagName("nav")[0].classList.remove("transparent-header")
//     } else {
//       document.getElementsByTagName("nav")[0].classList.add("transparent-header")
//     }
//   }
    return (

        <>

            <Navbar expand="lg" bg="dark" variant="dark" className='sticky-top p-0'>
                <Container fluid className='px-5 py-2'>
                    <Navbar.Brand href="/" className='fw-bold fs-3'>
                        <img
                            src={logo}
                            width="80"
                            height="50"
                            className="d-inline-block align-top "
                            alt="logo"
                        />
                        {/* <img src={logo} className='img-fluid'/> */}
                        {/* <span className='px-2'>Smart Coach</span> */}
                        </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className='transparent-header'>
                            {/* <div className="wrapper p-2 text-white mx-3 rounded fw-bold">Video Analysis Course Coming Soon...</div> */}

                            <Nav.Link as={Link} to="/" className='text-uppercase text-center mx-2 fw-bold text-theme'>Home</Nav.Link>

                            <Nav.Link as={Link} to="/About" className='text-uppercase text-center mx-2 fw-bold'>About Us</Nav.Link>

                            {/* <Nav.Link as={Link} to="/" className='text-uppercase text-center mx-2 fw-bold'>Solutions</Nav.Link> */}

                            {/* <Nav.Link as={Link} to="/" className='text-uppercase text-center mx-2 fw-bold '>Products</Nav.Link> */}

                            <NavDropdown title="PRODUCTS" id="basic-nav-dropdown" className='text-center fw-bold'>
                            <NavDropdown.Item as={Link} to="/SmartcoachDesktop" >Smart Coach Desktop</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/SmartCoachApp" >Smart Coach App</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/VideoAnalysisCourse" >Video Analysis Course</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/SportsManagement" >Sports Management</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/AnalyzerPlayer" >Analyzer Player</NavDropdown.Item>

                                <NavDropdown.Item as={Link} to="/VideoAnalysis" >Video Analysis</NavDropdown.Item>

                            </NavDropdown>

                            <Nav.Link as={Link} to="/Pricing" className='text-uppercase text-center mx-2 fw-bold'>Pricing</Nav.Link>
                            <Nav.Link as={Link} to="/Contact" className='text-uppercase text-center mx-2 fw-bold'>Contact</Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </>
    )

}

export default Header;