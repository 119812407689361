import React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import './App.css';
import Layout from './Pages/Layout';
import Home from "./Pages/Home";
import NoPage from "./Pages/NoPage";
import About from './Pages/About';
import Privacy from './Pages/Privacy';
import UserGuide from './Pages/UserGuide';
import TechnicalSupport from './Pages/TechnicalSupport';
import KeyFeatures from './Pages/KeyFeatures';
import Benefits from './Pages/Benefits';
import Products from './Pages/Products';
import DemoVideo from './Pages/DemoVideo';
import Contact from './Pages/Contact';
import VideoAnalysis from './Pages/VideoAnalysis';
import SportsManagement from './Pages/SportsManagement';
import VideoAnalysisCourse from './Pages/VideoAnalysisCourse';
import Pricing from './Pages/Pricing';
import Terms from './Pages/Terms';
import Disclaimer from './Pages/Disclaimer';
import Copyright from './Pages/Copyright';
import SmartCoachApp from './Pages/SmartCoachApp';
import QuoteCards from './Components/QuoteCards';
import SmartcoachDesktop from './Pages/SmartcoachDesktop';
import Downloads from './Pages/Downloads';
import Login from './Pages/LoginPage';
import Otp from './Pages/Otp';
import Upload from './Pages/Upload';
import { useLocation} from 'react-router-dom';
import {useLayoutEffect} from 'react';
import UserForm from './Pages/UserForm';
import Course from './Pages/Course';
import Demo from './Pages/Demo';
import NewForm from './Pages/NewForm';
import AnalyzerPlayer from './Pages/AnalyzerPlayer';
import MediaSetup from './Pages/MediaSetup';
import AccountDeleteProcess from './Pages/AccountDeleteProcess';
const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 
function App() {
  return (
    <BrowserRouter>
  <Wrapper>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/userguide" element={<UserGuide />} />
        <Route path="/technicalsupport" element={<TechnicalSupport />} />
        <Route path="/keyfeatures" element={<KeyFeatures />} />
        <Route path="/benefits" element={<Benefits />} />
        <Route path="/products" element={<Products />} />
        <Route path="/demovideo" element={<DemoVideo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/videoanalysis" element={<VideoAnalysis />} />
        <Route path="/sportsmanagement" element={<SportsManagement />} />
        <Route path="/videoanalysiscourse" element={<VideoAnalysisCourse />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/copyright" element={<Copyright />} />
        <Route path="/smartcoachapp" element={<SmartCoachApp />} />
        <Route path="/quotecards" element={<QuoteCards />} />
        <Route path="/smartcoachdesktop" element={<SmartcoachDesktop />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/login" element={<Login />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/userform" element={<UserForm />} />
        <Route path="/course" element={<Course />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/newform" element={<NewForm />} />
        <Route path="/analyzerplayer" element={<AnalyzerPlayer />} />
        <Route path="/mediasetup" element={<MediaSetup />} />
        <Route path="/accountdeleteprocess" element={<AccountDeleteProcess />} />

        
        <Route path="*" element={<NoPage />} />

      </Route>
    </Routes>
  </Wrapper>
  </BrowserRouter>
  );
}

export default App;
