import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import v1 from '../../Assets/video/vid1.mp4';
import v2 from '../../Assets/video/vid2.mp4';
import v3 from '../../Assets/video/vid3.mp4';

import v4 from '../../Assets/video/vid4.mp4';
function DemoVideo() {
    var vid = [{ src: v1 }, { src: v2 }, { src: v3 }, { src: v4 }]

    return (

        <>


            <Container fluid className=''>
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Demo Video</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Demo Video</a></div>
                    </Col>

                </Row>
                <Row className='bgBlack '>


                    {vid.map((video, index) => {
                        return (<Col key={index}>


                            <div className='text-center p-3' >

                                <iframe
                                    src={video.src}
                                    title="Vimeo video"
                                    allowFullScreen
                                    className='rounded-4 p-1'
                                ></iframe>
                            </div>
                        </Col>)
                    })}

                </Row>
              
            </ Container >


        </>
    )

}

export default DemoVideo;