import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import v1 from '../../Assets/video/vid1.mp4';
import v2 from '../../Assets/video/vid2.mp4';
import v3 from '../../Assets/video/vid3.mp4';
import v4 from '../../Assets/video/vid4.mp4';

function Course() {
    // var vid = [{ src: v1 }, { src: v2 }, { src: v3 }, { src: v4 }]

    var cap = [{ caption: "Chapter 1: Intro 1" }, { caption: "Chapter 2: Intro 2" }, { caption: "Chapter 3: Intro 3" }, { caption: "Chapter 4: Intro 4" }]

    return (

        <>


            <Container fluid className=''>
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Demo Video</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Demo Video</a></div>
                    </Col>

                </Row>
                <Row className='bgBlack '>
                    <Col sm={4} className='bg-success'>
                        <h1 className='text-center p-3'>Course Content</h1>

                        {/* {vid.map((video, index) => {
                            const shoot = () => {
                                alert("Introduction");
                            }
                            return (<Col key={index} sm={12} className='m-3'>
                                <div className=' text-center p-3' >
                                    <h6 onClick={shoot}
                                    >Introduction</h6>
                                    <iframe
                                        src={video.src}
                                        title="Vimeo video"
                                        allowFullScreen
                                        className='rounded-4 p-1'
                                    ></iframe>
                                </div>
                            </Col>)
                        })} */}

                        {cap.map((captext, index) => {
                            const demo = () => {
                                document.getElementById("hi").innerHTML = "<div className='section'><video controls><source src='https://file-examples.com/wp-content/uploads/2017/04/file_example_MP4_480_1_5MG.mp4' type='video/mp4'></video></div>"
                            }
                            return (<Col key={index} sm={12} className=''>
                                <div className=' text-center p-3' >

                                    <div className='text-white text-center' onClick={demo}>{captext.caption}</div>

                                </div>
                            </Col>)
                        })}





                    </Col>
                    <Col sm={8} className='bg-light  '>
                        <h1 className='text-center text-success' >Title Page</h1>
                        <div className='d-flex justify-content-center align-items-center m-5'>

                            <div id="hi"></div>

                        </div>

                    </Col>



                </Row>

            </ Container >


        </>
    )

}

export default Course;