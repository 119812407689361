import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
function Disclaimer() {

    return (

        <>


            <Container fluid className=''>
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Disclaimer</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Disclaimer</a></div>
                    </Col>

                </Row>
                <Row className='bgBlack'>


                    <Col className='m-3'>

                    <h3>Disclaimer</h3>
                        <p className='text-grey'>The information contained in this website is for general information purposes only. The information is provided by Franctel Network Pvt Ltd and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
                        <p className='text-grey'>In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
                        <p className='text-grey'>Through this website you are able to link to other websites which are not under the control of Franctel Network Pvt Ltd. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
                        <p className='text-grey'>Every effort is made to keep the website up and running smoothly. However, Franctel Network Pvt Ltd takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
                       
                
                       
    

                    </Col>

                </Row>
            </ Container >


        </>
    )

}

export default Disclaimer;