import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';


function LoginPage() {

  return (
    <>
      <Container fluid className='bgUserform'>
        <Row className='d-flex justify-content-center justify-content-center'>
          <Col className='text-center' lg={6}>
            <h1 className='m-3'>Login Here </h1>

            <form className='border rounded m-5 shadow'>
              <div className="mx-5 my-5 ">
                {/* <label htmlFor="exampleInputUsername" className="form-label" >User Name</label> */}
                <input type="email" className="form-control shadow" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Username" />
              </div>
              <div className="mx-5 my-5">
                {/* <label htmlFor="exampleInputPassword1" className="form-label">Password</label> */}
                <input type="password" className="form-control shadow" id="exampleInputPassword1" placeholder="Enter Password" />
              </div>

              <button type="submit" className="btn btn-success mx-5 my-3 shadow">Submit</button>
            </form>
          </Col>
        </Row>
      </Container>

    </>
  )

}

export default LoginPage;