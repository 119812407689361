import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
function Copyright() {

    return (

        <>


            <Container fluid className=''>
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Copyright</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Copyright</a></div>
                    </Col>

                </Row>
                <Row className='bgBlack'>


                    <Col className='m-3'>

                    <h3>Copyright</h3>
                
                        <p className='text-grey'>This website and its content is copyright of Franctel Network Pvt Ltd - © Franctel Network Pvt Ltd 2023. All rights reserved.</p>
                        <div>
                        <ul>
                        <li className='text-grey'>you may print or download to a local hard disk extracts for your personal and non-commercial use only</li>
                        <li className='text-grey'>you may copy the content to individual third parties for their personal use, but only if you acknowledge the website as the source of the material</li>
                        </ul>
                        </div>
                        <p className='text-grey'>You may not, except with our express written permission, distribute or commercially exploit the content. Nor may you transmit it or store it in any other website or other form of electronic retrieval system.</p>
                        
                    </Col>

                </Row>
            </ Container >


        </>
    )

}

export default Copyright;