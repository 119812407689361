import React from 'react';
import {  Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
// import sportsmanagement from '../../Assets/sportsmanagement.jpg';
import Vid1 from '../../Assets/Badminton.mp4'

function SportsManagement() {

    return (

        <>


            <Container fluid className=''>
            <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Sports Management</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Sports Management</a></div>
                    </Col>                
            </Row>
                <Row className='bgBlack'>


                    <Col className='p-3'>
                    <h3 className=' p-4 text-theme '>Sports Management</h3>
                    <h4 className='px-4 text-grey'>Training courses providers for following</h4>
                    <p className='px-4 text-grey'><span className='fw-bold'>Cricket-</span>  Coaching, Video and performance analysis, Scorer, Umpires</p>
                    <p className='px-4 text-grey' > <span className='fw-bold'>Table tennis, Badminton and Football-</span>  Coaching</p>
                    <p className='px-4 text-grey'>Sports event organiser</p>
                    <p className='px-4 text-grey'>Cricket, Badminton, Table tennis and football tournament organiser</p>
                    <p className='px-4 text-grey'>Sports Tours within India and also to England, South Africa, Dubai, Malaysia, Singapore and
Sri Lanka</p>

                    </Col>
                    {/* <Col className='p-3'>
                        <img src={sportsmanagement} alt='sportsmanagement' className='img-fluid p-3'/>
                    </Col> */}
   <Col className='text-center p-3'>
                    <div className='p-4'>
<video width="420" height="340" controls className=' rounded'>
    <source src={Vid1} type="video/mp4" />
        <source src="movie.ogg" type="video/ogg" />
            Your browser does not support the video tag.
 </video></div>

    </Col>
                </Row>
            </ Container >


        </>
    )

}

export default SportsManagement;