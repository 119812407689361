import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function Contact() {
   return (
      <>
         <Container fluid className='bgBlack'>
            <Row className='headingimg'>
               <Col className='m-4'>
                  <h1 className='text-white m-5 px-2 fw-bold text-center'>CONTACT US</h1>
                  <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right fa-beat"></i><a className='text-white text-decoration-none m-3'>CONTACT US</a></div>
               </Col>
            </Row>
            <Row>
               <Col>
                  <h6 className='text-grey m-3'>We Are Available</h6>
                  <h4 className='m-3'>GET IN TOUCH</h4>
                  <p className='m-3 text-grey'>You can talk to our online representative at any time. Please use our Live Chat System on our website or Fill up below instant messaging programs. Please be patient, <br /> We will get back to you. Our 24/7 Support, General Inquireies Phone: + 91  9850900325</p>
               </Col>
            </Row>
            <Row className='m-3 p-3'>
               
               <Col lg={4} >
                  <Row className=' m-1 border p-2 shadow'>
                     <Col sm={2} className='p-2 text-sm-end text-center'><i className="fa-solid fa-envelope fs-1 text-theme"></i> </Col>
                     <Col ><div className='text-sm-start text-center text-grey'>franctel.network1@gmail.com</div></Col>
                  </Row></Col>
                  <Col lg={4}>
                  <Row className='m-1 border p-2 shadow'>
                     <Col sm={2} className='p-2 text-sm-end text-center'><i className="fa-solid fa-location-dot fs-1 text-theme"></i> </Col>
                     <Col ><div className='text-sm-start text-center text-grey'>402, Zara Bldg, Nahars Amrit Shakti, Powai
Chandivali Studio, Andheri East, Mumbai, Maharashtra 400072.</div></Col>
                  </Row>
               </Col>
               <Col lg={4}>
                  <Row className='m-1 border p-2 shadow'>
                     <Col sm={2} className='p-2 text-sm-end text-center'><i className="fa-solid fa-phone fs-1 text-theme"></i></Col>
                     <Col ><div className='text-sm-start text-center text-grey'>+91  8983972605</div></Col>
                  </Row></Col>

            </Row>
            <Row>
               <Col lg={8} >

                  <Form className='m-3 bgBlack rounded-4' id="contact-form">


                     <Row className='m-3 p-3 '>

                        <Col lg={12} >
                           <Form.Control type="text" placeholder="Enter Name" className='p-2 m-2' name="from_name" id="from_name" required/>
                        </Col>
                        <Col lg={12} >
                           <Form.Control type="email" placeholder="Enter email" className='p-2 m-2' name="user_email" required/>
                        </Col>
                        <Col lg={12}>
                           <Form.Control type="text" placeholder="Phone" className='p-2 m-2' name="contact_number" required/>
                        </Col>
                        <Col lg={12}>
                           <Form.Control as="textarea" rows={3} className='p-2 m-2' name="message" id="message" required/>
                        </Col>
                        <Col lg={12} >

                        <Button variant="warning" type="submit" className='p-2 m-2 mb-4 fw-bold'>
                        <Form.Control type="submit" value="Send" />
                           </Button>
                        

                        </Col>

                     </Row>
                  </Form>

               </Col>
               <Col lg={4} className="p-3 text-center">
                     <div className='m-3'>
                        <a href="https://www.youtube.com/channel/UCL4g2dA0Z078TxbpIQIZMmA" target="_blank"  className='text-decoration-none text-grey'><i className="fa-brands fa-youtube fs-1 mx-2 text-danger rotate"></i>Follow on Youtube</a>
                     </div>
                     <div className='m-3'>
                        <a href="https://www.linkedin.com/in/smart-coach-b02bbb270/" target="_blank" className='text-decoration-none text-grey'><i className="fa-brands fa-linkedin fs-1 mx-2 text-primary rotate"></i>Follow on Linkedin</a>
                     </div>
                     <div className='m-3'>
                        <a href="https://www.instagram.com/smartcoach.co.in/" target="_blank" className='text-decoration-none text-grey'><i className="fa-brands fa-instagram fs-1 mx-2 text-danger rotate" ></i>Follow on Instagram</a>
                     </div>
                     <div className='m-3'>
                        <a href="https://www.facebook.com/profile.php?id=100066258716847" target="_blank" className='text-decoration-none text-grey'><i className="fa-brands fa-facebook fs-1 mx-2 text-primary rotate"></i>Follow on facebook</a>
                     </div>
                    
                     <div className='m-3'>
                        <a href="https://twitter.com/SmartcoachCo" target="_blank" className='text-decoration-none text-grey'><i className="fa-brands fa-twitter fs-1 mx-2 text-info rotate"></i>Follow on twitter</a>
                     </div>

               </Col>
            </Row>
           
         </Container>


      </>

   )

}

export default Contact;