import React from 'react';
import { Button, Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Guide from '../../Assets/guide.pdf';
function userGuide() {

    return (

        <>


            <Container fluid className=''>
            <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>User Guide</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>User Guide</a></div>
                    </Col>
                    
                </Row>
                <Row className='bgBlack'>


                    <Col className='p-3'>
                    <h3 className='text-center p-4 text-theme '>User Guide</h3>
                   <p>The USER GUIDE will give all the details about the video Capturing and Video Analysis process</p>
                 <Button variant="primary" ><a href={Guide} target='_blank' className='text-white text-decoration-none'>Click Download</a> </Button>

                   
                   
                       
                    </Col>

                </Row>
            </ Container >


        </>
    )

}

export default userGuide;