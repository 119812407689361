import React from 'react';
import {  Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
// import videoCap from '../../Assets/videocap.jpg';
// import singleCam from '../../Assets/singlecam.jpg';
// import importcam from '../../Assets/importcam.jpg';
function Products() {

    return (

        <>


            <Container fluid className=''>
            <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Products</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='#' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Products</a></div>
                    </Col>
                    
                </Row>
                <Row className='bgBlack '>


                    <Col className='p-3'>
                    <h3 className='text-center p-4 text-theme '>Products</h3>
                   
                    </Col>

                </Row>
            </ Container >


        </>
    )

}

export default Products;