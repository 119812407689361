
import React from 'react';
import Rev2 from '../../Assets/rev2.jpg';
import Rev3 from '../../Assets/rev3.png';

import Avtar from '../../Assets/avtar.jpg';
function Testimonials() {
    return (
        <>

            <div className="container text-dark">
                <div className="row g-2 m-lg-5">
                    <h3 className='text-center text-theme text-uppercase p-3'>Testimonials</h3>


                    <div className="col-md-4">
                        <div className="card p-3 text-center">
                            <div className="user-image">
                                <img src={Rev2} className="rounded-circle" width="80" alt='img1' />
                            </div>
                            <div className="user-content">
                                <h5 className="mb-0">Gayatri Naik</h5>
                                <span>Video Analyst</span>
                                <p className='textJustify'>Hello Stanley Sir.
                                    I hope this message finds you well. I am writing to express my heartfelt gratitude for the incredible
                                    journey I’ve had during the Analyser Video Analyst course and the subsequent opportunities it has
                                    opened up for me.
                                    Completing the Video Analyst course was an enriching experience that expanded my knowledge and
                                    honed my skills in ways I couldn’t have imagined. The curriculum’s depth and the dedication of the
                                    instructors truly transformed my understanding of the video analysis required for cricket.
                                    I am thrilled to share that the course played an instrumental role in helping me secure a position as a
                                    Video Analyst at Baroda Cricket Association for their U-19 women’s cricket team.
                                    I would like to extend my gratitude to you and the entire team for creating an environment conducive
                                    to learning and growth. Your expertise, guidance, and unwavering support were pivotal in shaping my
                                    journey. I look forward to staying connected and contributing to the field of video analysis. Please feel
                                    free to reach out if there are any opportunities for collaboration or if I can be of assistance to future
                                    students.
                                </p>
                            </div>
                            <div className="ratings">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="card text-center p-3">
                            <div className="user-image">
                                <img src={Rev3} className="rounded-circle" width="80" alt='img1' />
                            </div>
                            <div className="user-content">
                                <h5 className="mb-0"> Swapnil Kadam </h5>
                                <span>Video Analyst</span>
                                <p className='textJustify'>
                                    My sincere thanks from the bottom of my heart to Stanley Sir for providing me with an opportunity to train under his supervision with his team as a video and performance analyst. I have secured in-depth knowledge of the entire process of technical aspects and using the software Analyzer and Smart Coach which are used across all state associations. I am excited to share that the course has provided me with an opportunity to be a part of the Maharashtra Cricket Association’s performance analyst for the senior team. Stanley Sir’s expertise, guidance and support have played a vital role in shaping my journey as a performance analyst. The best part was providing expensive software for practice purposes.
                                </p>
                            </div>
                            <div className="ratings">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="card text-center p-3 ">

                            <div className="user-image">

                                <img src={Avtar} className="rounded-circle" width="80" alt='img1' />

                            </div>

                            <div className="user-content">

                                <h5 className="mb-0">Shiladitya Banerjee</h5>
                                <span>Video Analyst</span>
                                <p className='textJustify'>Hi this is Shiladitya Banerjee, I have completed online video and performance analysis course under the supervision of Mr. Stanley Saldanha Sir. After completion of this course I have been contracted with CAB as a video analyst. I have learnt many technical things from this course which helps me to crack the interview. I am very much grateful to Stanley Sir for giving me the opportunity to learn this precious technology.</p>

                            </div>

                            <div className="ratings">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>

                            </div>

                        </div>
                    </div>




                </div>

            </div>


        </>
    )

}

export default Testimonials;